
.modalCloseWarning {
    position: fixed; /* фиксированное положение */
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0,0,0,0.5); /* цвет фона */
    display: flex;
    align-items: center;
    justify-content: center;
  z-index: 1055;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modalCloseWarningCloseWarning__dialog {
    width: 385px;
    background-color: #FFFFFF;
    border-radius: 10px;
}

.modalCloseWarning__content {
    padding: 24px 28px 16px 28px;
}

.modalCloseWarning__title {
    font-weight: 600;
    font-size: 16px;
    line-height: 1;
    color: #5932EA;
    /* margin-bottom: 16px; */
}


.modalCloseWarning__body {
    height: 16px;
    display: flex;
    align-items: center;
    margin-bottom: 28px;
}

.modalCloseWarning__header {
    height: 22px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 16px;
}

.modalCloseWarning__body-description,
.modalCloseWarning__body-description__bolt  {
    font-size: 12px;
    line-height: 1;
    color: #232360;
}

.modalCloseWarning__body-description {
    font-weight: 400;
}

.modalCloseWarning__body-description__bolt {
    font-weight: 600;
    margin-left: 3px;    
}

.modalCloseWarning__btn-group {
    display: flex;
    justify-content: flex-end;
}

.modalCloseWarning__btn-group__close,
.modalCloseWarning__btn-group__save {
    line-height: 16px;
    font-size: 12px;
    padding: 7px;
    border: none;
    border-radius: 6px;
    background-color: transparent;
    outline: none;
}

.modalCloseWarning__btn-group__close {
    width: 61px;
    border: 1px solid #E1E4ED;
    background-color: #FFFFFF;
    color: #232360;
    margin-right: 8px;
}

.modalCloseWarning__btn-group__save {
    width: 82px; 
    background-color: #5932EA;
    color: #FFFFFF;
    font-weight: 600;
}